<template>
  <c-box :w="{ lg: '50%', base: '100%' }">
    <v-select
      :options="['Yes', 'No']"
      class="vc-select"
      :clearable="false"
      v-model="companyRegistered"
      placeholder="Select One"
      :searchable="false"
    />
    <c-box mt="2" v-if="companyRegistered === 'Yes'">
      <c-text mb="2" fontSize="sm">Select company type</c-text>
      <v-select
        :options="companyTypes"
        class="vc-select"
        :clearable="false"
        v-model="companyType"
        placeholder="Select One"
        :searchable="false"
        :appendToBody="true"
        v-if="!isOtherCompanyType"
      />
      <c-input
        v-else
        @input.native="onOtherCompanyInput"
        v-model="otherCompanyType"
      ></c-input>
    </c-box>
  </c-box>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      companyRegistered: 'No',
      companyType: null,
      otherCompanyType: null,
      companyTypes: [
        'Sole Proprietorship',
        'Partnerships',
        'Limited Partnership',
        'Corporation',
        'Limited Liability Company (LLC)',
        'Nonprofit Organization',
        'Cooperative',
        'Other',
      ],
    };
  },
  computed: {
    isOtherCompanyType() {
      return this.companyType == 'Other';
    },
  },
  watch: {
    value() {
      this.setValue();
    },
    companyType() {
      if (this.companyType !== 'Other') {
        this.$emit('input', this.companyType);
      } else {
        this.$emit('input', null);
      }
    },
    companyRegistered() {
      this.companyType = null;
      if (this.companyRegistered === 'No') {
        this.$emit('input', 'No');
      } else {
        this.$emit('input', null);
      }
    },
  },
  created() {
    this.setValue();
  },
  methods: {
    onOtherCompanyInput() {
      this.$emit('input', this.otherCompanyType);
    },
    setValue() {
      if (this.value !== 'No') {
        this.companyRegistered = 'Yes';
        if (this.companyTypes.includes(this.value)) {
          this.companyType = this.value;
        } else {
          this.otherCompanyType = this.value;
        }
      } else {
        this.companyRegistered = 'No';
      }
    },
  },
};
</script>

<style></style>
