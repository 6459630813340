<template>
  <div class="work">
    <div class="work__department">
      <span>I work in </span>
      <v-select
        label="name"
        :reduce="(department) => department.name"
        :options="departments"
        class="vc-select"
        :clearable="false"
        v-model="selectedDepartment"
        @option:selected="onDepartmentSelect"
      >
        <template #no-options="{ search }">
          <span class="vc-select__no-option"> Add {{ search }} </span>
        </template>
      </v-select>
    </div>
    <div class="work__role">
      <span>as a</span>
      <v-select
        :disabled="!selectedDepartment"
        :clearable="false"
        label="name"
        :reduce="(role) => role.name"
        class="vc-select"
        :options="roles"
        v-model="selectedRole"
        ref="roleSelectRef"
      >
        <template #no-options="{ search }">
          <span class="vc-select__no-option" @click="setCustomRole(search)">
            {{ search ? 'Add ' + search : 'No options' }}
          </span>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['value', 'field'],
  data() {
    return {
      roles: [],
      selectedDepartment: null,
      selectedRole: null,
    };
  },
  computed: {
    ...mapState('config', {
      departments: (state) => state.departments,
    }),
  },
  created() {
    if (this.value) {
      this.selectedDepartment = this.value.department;
      this.selectedRole = this.value.role;
    }
  },
  methods: {
    onDepartmentSelect(department) {
      this.selectedRole = null;
      this.roles = department.team_positions;
    },
    setCustomRole(value) {
      this.selectedRole = value;
      this.$refs.roleSelectRef.$el.getElementsByTagName('input')[0].blur();
    },
  },
  watch: {
    selectedRole(val) {
      if (val) {
        this.$emit('input', {
          department: this.selectedDepartment,
          role: this.selectedRole,
        });
      } else {
        this.$emit('input', null);
      }
    },
    value(val) {
      this.selectedDepartment = val.department;
      this.selectedRole = val.role;
    },
  },
};
</script>

<style lang="scss" scoped>
.work {
  @apply flex flex-wrap;
  &__department,
  &__role {
    @apply flex flex-wrap items-center w-full mb-4;
    span {
      @apply text-sm;
      margin-right: 10px;
    }
    .v-select {
      min-width: 11rem;
      margin: 10px 0;
      @screen lg {
        margin: 0 20px;
        // min-width: 14rem;
      }
    }
    @screen lg {
      @apply flex-nowrap w-max mr-3;
      max-width: 80%;
    }
  }
  @screen lg {
    @apply flex-wrap;
  }
  @screen xl {
    @apply flex-nowrap;
  }
}
</style>
