<template>
  <div class="location">
    <span class="location__label">{{ field.placeholder }}</span>
    <div class="location__fields">
      <v-select
        label="name"
        :options="countries"
        class="vc-select"
        :clearable="false"
        @option:selected="onCountrySelect"
        v-model="selectedCountry"
        placeholder="Country"
      >
      </v-select>
      <v-select
        :disabled="!selectedCountry"
        :options="cities"
        class="vc-select"
        :clearable="false"
        v-model="selectedCity"
        placeholder="City"
      >
      </v-select>
    </div>
  </div>
</template>

<script>
import countriesJSON from '@/helpers/countries.json';

export default {
  props: ['field', 'value'],
  data() {
    return {
      countries: [],
      cities: [],
      selectedCountry: null,
      selectedCity: null,
    };
  },
  created() {
    if (this.value) {
      this.selectedCountry = this.value.country;
      this.selectedCity = this.value.city;
    }
    this.countries = Object.keys(countriesJSON).map((country) => {
      return {
        name: country,
        cities: countriesJSON[country],
      };
    });
  },
  watch: {
    value(val) {
      this.selectedCity = val.city;
      this.selectedCountry = val.country;
    },
    selectedCity(val) {
      if (val) {
        this.$emit('input', {
          country: this.selectedCountry.name,
          city: this.selectedCity,
        });
      } else {
        this.$emit('input', null);
      }
    },
  },
  methods: {
    onCountrySelect(country) {
      this.selectedCity = null;
      this.cities = country.cities;
    },
  },
};
</script>

<style lang="scss">
.location {
  &__label {
    @apply text-sm;
    margin-bottom: 5px;
  }
  &__fields {
    @apply flex flex-wrap;
    .v-select {
      @apply mt-5;
      min-width: 200px;
      &:first-child {
        margin-right: 20px;
      }
    }
    @screen lg {
      @apply flex-nowrap;
    }
  }
}
</style>
