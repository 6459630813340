<template>
  <c-box>
    <v-select
      :options="channels"
      class="vc-select"
      :clearable="false"
      v-model="channel"
      placeholder="Select One"
      :searchable="false"
      v-if="!isOtherChannel"
      :appendToBody="true"
    />
    <c-input
      v-else
      @input.native="onOtherInput"
      v-model="otherInputValue"
    ></c-input>
  </c-box>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      channels: [
        'Search engine (google, Yahoo, etc)',
        'Incubators/Accelerators',
        'Recommended by a friend or colleague',
        'Social media',
        'Affiliate',
        'Blog or publication',
        'Through professional or social contacts',
        'Other',
      ],
      channel: 'Search engine (google, Yahoo, etc)',
      otherInputValue: null,
    };
  },
  computed: {
    isOtherChannel() {
      return this.channel == 'Other';
    },
  },
  mounted() {
    const storedReferralCode = localStorage.getItem('referral_code');
    if(storedReferralCode){
      this.channel = 'Affiliate'
    }
  },
  watch: {
    channel() {
      if (this.channel !== 'Other') {
        this.$emit('input', this.channel);
      } else {
        this.$emit('input', null);
      }
    },
  },
  methods: {
    onOtherInput() {
      this.$emit('input', this.otherInputValue);
    },
    setValue() {
      if (this.channels.includes(this.value)) {
        this.channel = this.value;
      } else {
        this.channel = 'Other';
        this.otherInputValue = this.value;
      }
    },
  },
};
</script>

<style></style>
