<template>
  <div style="max-width: 250px">
    <div class="flex items-center">
      <input
          :value="value"
          v-bind="$attrs"
          v-on="{
            ...$listeners,
            input: (event) => handleInput('input', event.target.value),
          }"
          type="text"
          :placeholder="field.placeholder"
          :disabled="loading"
      />
      <c-spinner v-if="loading" style="margin-left: -40px" size="sm" />
    </div>
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import {getCompanyWithReferralCode} from "@/services/company";

export default {
  name: 'ReferralCodeField',
  props: ['value', 'field'],
  mounted() {
    console.log('Fixy==>', this.value);
    this.handleInput(this.value);
  },
  data(){
    return{
      loading: false,
      errorMessage: null,
      referralDetails: null
    }
  },
  methods: {
    async handleInput(referralCode) {
      console.log(referralCode, 'Scalingxxx');
      this.$emit('input', referralCode); // Update the model in the parent component

      if (referralCode.length === 6) {
        this.loading = true;
        this.errorMessage = null;
        this.referralDetails = null;

        try {
          // Simulate API request to fetch referral details
          const response = await this.fetchReferralDetails(referralCode);

          // Check if referral exists, handle success and failure
          if (response.success) {
            this.referralDetails = response.data.company;
            this.$emit('referralDetails', this.referralDetails)
            console.log('Referral details', this.referralDetails)
          } else {
            this.errorMessage = "Invalid referral code. Please try again.";
            console.log('Referral details', null)
          }
        } catch (error) {
          this.errorMessage = "Something went wrong. Please try again.";
        } finally {
          this.loading = false;
        }
      }
    },

    async fetchReferralDetails(referralCode){
      try{
        const response = await getCompanyWithReferralCode(referralCode)
        return { success: true, data: response.data }
      }catch (e) {
        return { success: false }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  height: 45px;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  color: $color-grey;
  border: 1px solid #ccc; /* Default border color */
  padding: 10px;
  border-radius: 4px;
  transition: border-color 0.3s;
  outline: none;
  margin: 10px 0;
}
input:focus {
  border-color: #da5711; /* Border color when focused */
  outline: none; /* Remove default outline */
}
.error-message {
  margin-top: 10px;
  color: red;
  font-size: 0.7rem;
}
</style>