<template>
  <input
    :value="value"
    v-bind="$attrs"
    v-on="{
      ...$listeners,
      input: (event) => $emit('input', event.target.value),
    }"
    type="text"
    :placeholder="field.placeholder"
  />
</template>

<script>
export default {
  props: ['value', 'field'],
};
</script>

<style lang="scss" scoped>
input {
  height: 50px;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  color: $color-grey;
  outline: none;
  margin: 10px 0;
}
</style>
